import { createSlice } from '@reduxjs/toolkit';

const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState: {
    logoImage: '',
    favicon: '',
    isToggleThemeCustomize: false,
    smtp: null,
    smtpSaved: null,
  },
  reducers: {
    addUpdateIsToggleThemeCustomizeAction(state, action) { 
      state.isToggleThemeCustomize = action.payload;
    },
    fetchDsaAccountSmtp(state, action) { 
      state.smtp = action.payload;
    },
    fetchDsaAccountSmtpSavedSuccess(state, action) { 
      state.smtpSaved = action.payload;
    },
    addLogoImageAction(state, action) { 
      state.logoImage = action.payload;
    },
    addFaviconAction(state, action) { 
      state.favicon = action.payload;
    },
    addImagesOfAccount(state, action) { 
      if (action.payload?.favicon) state.favicon = action.payload?.favicon || '';
      if (action.payload?.logoImage) state.logoImage = action.payload?.logoImage || '';
    },
    
  },
});

export const {addUpdateIsToggleThemeCustomizeAction, fetchDsaAccountSmtpSavedSuccess, fetchDsaAccountSmtp, addImagesOfAccount} = accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;