import React, { createContext, useEffect, useState } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { JWTContextType } from 'types/auth';

import { useDispatch, persister, useSelector } from 'store';
import { accountDetailsUpdate, accountLogin, accountLogout } from '../store/state/data-slices/accountSlice';
import { openSnackbar } from 'store/slices/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import useConfig from 'hooks/useConfig';
import { addImagesOfAccount } from 'store/state/data-slices/dsa/accountSlice';

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        localStorage.removeItem('winpro-root');
        localStorage.removeItem('serviceToken');
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('winpro-root');

    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const { onChangeThemeConfigByInit } = useConfig();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const getQuery = useQuery();
    const path = location.pathname;
    const state = useSelector((state) => state.account);
    const [accountType, setAccountType] = useState('');
    const serviceToken = window.localStorage.getItem('serviceToken');
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    setSession(serviceToken);


    useEffect(() => {
        const init = async () => {

            try {
                if (path === '/admin-auth') {
                    const token: any = getQuery.get('token');
                    const verify = verifyToken(token);
                    if (verify) {
                        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                        const response = await axios.get(`/users/me`);
                        const { user } = response.data;
                        if (user) {
                            setSession(token);
                            dispatch(accountLogin({ isLoggedIn: true, user, accountType }));
                        }
                    }
                } else {
                    //const type = ['dsa', 'fi', 'admin'];
                    //const hostname = window.location.hostname;
                    // const accountSettings = await axios({
                    //     url: `/init?hostname=${hostname}`,
                    //     method: 'get',
                    //     data: {}
                    // });
                    // const responseData = accountSettings?.data?.data;
                    // if (responseData.accountThemeSettings?.themeJson) {
                    //     onChangeThemeConfigByInit(responseData.accountThemeSettings?.themeJson);
                    //     dispatch(addImagesOfAccount(responseData.accountThemeSettings));
                    // }
                    if (serviceToken && verifyToken(serviceToken)) {
                        const response = await axios({
                            url: `/users/me`,
                            method: 'get',
                            data: {}
                        });
                        const { user } = response.data;
                        dispatch(accountLogin({ isLoggedIn: true, user, accountType }));
                    } else {
                        await axios.post(`/auth/logout`);
                        setSession(null);
                        persister.purge();
                        dispatch(accountLogout({}));
                    }
                    // const { data } = accountSettings.data;
                    // dispatch(accountDetailsUpdate(data));
                    // if (data && data.type) {
                    //     setAccountType(data.type);

                    // }
                }

            } catch (err) {
                console.error(err);
                dispatch(accountLogout({}));
            }
        };

        init();
    }, []);

    const login = async (type: string, typeOfMessage: string, email: string, password: string, mobile: number, verifyCode: string, isLoginWithCode: number) => {
        const isEmail = type === 'email';
        const obj: any = { type, password, verifyCode, isLoginWithCode, typeOfMessage };
        if (isEmail) obj.email = email;
        if (!isEmail) obj.mobile = mobile;
        const response = await axios.post('/auth/login', obj);
        const { token } = response.data.data;
        setSession(token);
        const userMe = await axios.get('/users/me');
        const { user } = userMe.data;
        dispatch(accountLogin({ isLoggedIn: true, user, accountType }));

    };

    const adminLogin = async (type: string, typeOfMessage: string, email: string, password: string, mobile: number, verifyCode: string, isLoginWithCode: number) => {
        const isEmail = type === 'email';
        const obj: any = { type, password, verifyCode, isLoginWithCode, typeOfMessage };
        if (isEmail) obj.email = email;
        if (!isEmail) obj.mobile = mobile;
        const response = await axios.post(`/auth/login`, obj);
        const { token, user } = response.data.data;
        setSession(token);
        dispatch(accountLogin({ isLoggedIn: true, user, accountType }));
    };

    const register = async (email: string, password: string, name: string, mobile: string) => {
        const response = await axios.post('/auth/register', {
            email,
            password,
            name,
            mobile,
        });

        if (response.data && response.data.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            navigate('register', { replace: true });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }

    };

    const registerChannel = async (email: string, mobile: string, verifyCodeEmail: string, verifyCodeMobile: string, firstName: string, lastName: string,) => {
        const response = await axios.post('/auth/onboarding/channel/login', {
            email,
            verifyCodeEmail, verifyCodeMobile,
            firstName, lastName,
            mobile
        });

        if (response.data && response.data.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            const { token } = response.data.data;
            setSession(token);
            const userMe = await axios.get(`/dsa/users/me`);
            const { user } = userMe.data;
            dispatch(accountLogin({ isLoggedIn: true, user, accountType }));
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    };

    const logout = async (isAll?: boolean) => {
        setSession(null);
        persister.purge();
        persister.flush();
        dispatch(accountLogout({}));
        (await axios.post(`/auth/logout`, { isAll: isAll ? isAll : false }));

    };

    const resetPassword = (email: string) => {

    };

    const updateProfile = () => { };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, adminLogin, logout, register, registerChannel, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
