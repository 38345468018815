import { combineReducers } from 'redux';
import accountReducer from './data-slices/admin/accountSlice';
import usersReducer from './data-slices/admin/userSlice';
import roleReducer from './data-slices/admin/roleSlice';
import complaintCaseReducer from './data-slices/admin/complaintCaseSlice';
import productMasterSliceReducer from './data-slices/admin/productMasterSlice';
import productTypesSliceReducer from './data-slices/admin/productTypesSlice';
import translationsSliceReducer from './data-slices/admin/tranlationsSlice';

const admin = combineReducers({
    account: accountReducer,
    translations: translationsSliceReducer,
    users: usersReducer,
    roles: roleReducer,
    productMasters: productMasterSliceReducer,
    productTypes: productTypesSliceReducer,
    complaintCase: complaintCaseReducer,

})

export default admin;