import { createSlice } from '@reduxjs/toolkit';

const productTypesSlice = createSlice({
  name: 'productTypes',
  initialState: {
    productTypes: [],
    counts: 0,
    editProductType: {},
    addUpdateProductTypeSuccess: false,

  },
  reducers: {
    fetchProductTypes(state, action) { 
      state.productTypes = action.payload;
    },
    ProductTypesCount(state, action) { 
      state.counts = action.payload ? action.payload: 0;
    },
    addUpdateProductSuccess(state, action) { 
      state.addUpdateProductTypeSuccess = action.payload;
    },
    fetchEditProductType(state, action) { 
      state.editProductType = action.payload;
    },
  },
});

export const { fetchProductTypes, fetchEditProductType, ProductTypesCount , addUpdateProductSuccess} = productTypesSlice.actions;

export default productTypesSlice.reducer;