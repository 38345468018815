import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const Users = Loadable(lazy(() => import('views/admin/users')));
const AddUser = Loadable(lazy(() => import('views/admin/users/AddUser')));
const EditUser = Loadable(lazy(() => import('views/admin/users/EditUser')));
const RoleAndPermissions = Loadable(lazy(() => import('views/admin/roles')));
const AddRole = Loadable(lazy(() => import('views/admin/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/admin/roles/EditRole')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const AddLang = Loadable(lazy(() => import('views/admin/lang/AddLang')));
const EditLangs = Loadable(lazy(() => import('views/admin/lang/EditLangs')));
const LangList = Loadable(lazy(() => import('views/admin/lang')));

const AddPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const EditPage = Loadable(lazy(() => import('views/admin/staticPages/AddArticles')));
const PagesList = Loadable(lazy(() => import('views/admin/staticPages')));

const HomePage = Loadable(lazy(() => import('views/admin/staticPages/home')));
const AboutPage = Loadable(lazy(() => import('views/admin/staticPages/about')));
const ServicePage = Loadable(lazy(() => import('views/admin/staticPages/services')));
const ContactPage = Loadable(lazy(() => import('views/admin/staticPages/contact')));

const AddArticle = Loadable(lazy(() => import('views/admin/knowledgeBase/AddArticles')));
const EditArticle = Loadable(lazy(() => import('views/admin/knowledgeBase/EditArticles')));
const KnowledgeBase = Loadable(lazy(() => import('views/admin/knowledgeBase')));

const Complaint = Loadable(lazy(() => import('views/admin/complaint/index')));
const CreateCase = Loadable(lazy(() => import('views/admin/complaint/CreateCase')));
const ViewCase = Loadable(lazy(() => import('views/admin/complaint/ViewCase')));

const Product = Loadable(lazy(() => import('views/admin/product')));
const AddProduct = Loadable(lazy(() => import('views/admin/product/AddProduct')));
const EditProduct = Loadable(lazy(() => import('views/admin/product/EditProduct')));
const LoanCategoryFlow = Loadable(lazy(() => import('views/admin/product/productTypes/flow/AdminFlow')));

const ProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes')));
const AddProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/AddProductTypes')));
const EditProductTypes = Loadable(lazy(() => import('views/admin/product/productTypes/EditProductTypes')));

const AddUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/AddUsesTerms')));
const ViewUsesTerms = Loadable(lazy(() => import('views/admin/mailTemplates/')));
const EditMailTemplate = Loadable(lazy(() => import('views/admin/mailTemplates/EditAgreement')));


// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/lang',
            element: <LangList/>
        },
        {
            path: '/lang/add',
            element: <AddLang/>
        },
        {
            path: '/lang/edit/:id',
            element: <EditLangs/>
        },
        {
            path: '/pages',
            element: <PagesList/>
        },
        {
            path: '/pages/add',
            element: <AddPage/>
        },
        {
            path: '/pages/static/home',
            element: <HomePage/>
        },
        {
            path: '/pages/static/about',
            element: <AboutPage/>
        },
        {
            path: '/pages/static/contact',
            element: <ContactPage/>
        },
        {
            path: '/pages/static/services',
            element: <ServicePage/>
        },
        {
            path: '/pages/edit/:id',
            element: <EditPage/>
        },
        {
            path: '/mail-templates/list',
            element: <ViewUsesTerms/>
        },
        {
            path: '/mail-templates/create',
            element: <AddUsesTerms />
        },
        {
            path: '/mail-templates/view/:id',
            element: <EditMailTemplate/>
        },
        {
            path: '/complaint/list',
            element: <Complaint/>
        },
        {
            path: '/complaint/create',
            element: <CreateCase />
        },
        {
            path: '/complaint/view/:id',
            element: <ViewCase/>
        },
        {
            path: '/sub-product/list',
            element: <Product />
        },
        {
            path: '/sub-product-add',
            element: <AddProduct />
        },
        {
            path: '/sub-product/edit/:id',
            element: <EditProduct />
        },
        
        {
            path: '/product/list',
            element: <ProductTypes />
        },
        {
            path: '/product-add',
            element: <AddProductTypes />
        },
        {
            path: '/product/flow/view',
            element: <LoanCategoryFlow />
        },
        {
            path: '/product/edit/:id',
            element: <EditProductTypes/>
        },
        {
            path: '/faq/list',
            element: <KnowledgeBase/>
        },
        {
            path: '/faq/add',
            element: <AddArticle />
        },
        {
            path: '/faq/edit/:id',
            element: <EditArticle/>
        },
        {
            path: '/user/list',
            element: <Users />
        },
        {
            path: '/user/add',
            element: <AddUser />
        },
        {
            path: '/user/edit/:id',
            element: <EditUser />
        },
        {
            path: '/user/role/list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add',
            element: <AddRole/>
        }
        ,
        {
            path: '/user/role/edit/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }


    ],

};

export default AdminRoutes;
